<template>
  <div>
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-col cols="12" class="about">
        <v-card :loading="ui.loading" :disabled="ui.disabled">
          <template slot="progress">
            <v-progress-linear
              color="blue-grey darken-3"
              height="6"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            height="200px"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            src="../../assets/background-images/developpement.webp"
          >
            <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
            >
              <div class="px-5">
                <v-row>
                  <v-col cols="12" class="mt-14 pb-0">
                    <v-toolbar-title class="title white--text pl-0">
                      {{ $vuetify.lang.t('$vuetify.auth.profile.ui.form.title') }}
                    </v-toolbar-title>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="white--text">{{ $vuetify.lang.t('$vuetify.auth.profile.ui.form.subtitle') }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-app-bar>

            <v-card-title class="white--text mt-8">
              <div class="px-5 d-flex">
                <v-avatar size="56">
                  <img
                    alt="user"
                    src="https://www.hostpapa.ie/knowledgebase/wp-content/uploads/2018/04/1-13.png"
                  >
                </v-avatar>
                <p class="ml-3" style="position:relative;top: 8px">
                  {{ form.default_data.firstname + ' ' + _.toUpper(form.default_data.lastname) }}
                </p>
              </div>
            </v-card-title>
          </v-img>
          <v-card-text>
            <div class="px-5">
              <v-form ref="form" v-model="form.valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      v-model="ui.alert.enable"
                      :dismissible="ui.alert.dismissible"
                      :icon="ui.alert.icon"
                      :type="ui.alert.type"
                      class="mb-0 mt-3 mb-3"
                    >
                      {{ ui.alert.message }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.data.lastname"
                      :rules="form.rules.lastname"
                      :label="$vuetify.lang.t('$vuetify.auth.profile.ui.form.lastname')"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="form.data.firstname"
                      :rules="form.rules.firstname"
                      :label="$vuetify.lang.t('$vuetify.auth.profile.ui.form.firstname')"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="form.data.email"
                      :label="$vuetify.lang.t('$vuetify.auth.profile.ui.form.email')"
                      :rules="form.rules.email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.data.password"
                      :append-icon="ui.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="ui.show_password ? 'text' : 'password'"
                      name="password"
                      :label="$vuetify.lang.t('$vuetify.auth.profile.ui.form.password.password1')"
                      counter
                      @click:append="ui.show_password = !ui.show_password"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="form.data.password2"
                      :append-icon="ui.show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="ui.show_password2 ? 'text' : 'password'"
                      name="password"
                      :label="$vuetify.lang.t('$vuetify.auth.profile.ui.form.password.password2')"
                      hint="8 caractères minimum sont requis"
                      counter
                      @click:append="ui.show_password2 = !ui.show_password2"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-md-0">
                    <v-radio-group>
                      <v-radio
                        v-for="(sexe,k) in sexes"
                        :key="k"

                        :label="sexe"
                        :value="k"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="mt-6 pb-0 mb-0 text-caption">
                      {{ $vuetify.lang.t('$vuetify.global.form.required_fields') }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>

            </div>
          </v-card-text>
          <v-card-actions class="d-xs-block px-7">
            <v-btn
              text @click="reset()">
              {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="submit()">
              {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import _ from 'lodash'
import Sessions from '@/plugins/sessions'

export default {
  data: () => ({
    form: {
      valid: false,
      default_data: {},
      data: {
        lastname: '',
        firstname: '',
        email: '',
        password: '',
        password2: ''
      }
    },
    ui: {
      show_password: false,
      show_password2: false,
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    sexes: {
      male: 'Homme',
      female: 'Femme',
      other: 'Autre'
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()

      setTimeout(() => {
        this.form.data.lastname = this.form.default_data.lastname
        this.form.data.firstname = this.form.default_data.firstname
        this.form.data.email = this.form.default_data.email
        this.form.data.sex = this.form.default_data.sex
      }, 10)
    },
    validate () {
      return this.$refs.form.validate()
    },
    async fetchProfile () {
      try {
        this.ui.loading = true
        this.ui.disabled = true
        this.form.default_data = (await this.axios.get('/profile',
          {
            headers:
              { token: Sessions.get('auth_credentials').token }
          })).data
        this.reset()
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 204:
              await this.$router.push({
                name: 'Login',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.not_find')
                  }
                }
              })
              break

            default:
              await this.$router.push({
                name: 'Login',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert-octagon',
                    type: 'error',
                    message: this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.unknown_fetch')
                  }
                }
              })
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    async submit () {
      if (!this.validate()) {
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/profile/update', {
          lastname: this.form.data.lastname,
          firstname: this.form.data.firstname,
          email: this.form.data.email,
          sex: this.form.data.sex,
          password: this.form.data.password,
          confirm: this.form.data.password2
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        await this.fetchProfile()

        this.reset()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.auth.profile.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.auth.profile.notification.invalid_parameters'))
              break
            case 403:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.auth.profile.notification.password_not_match'))
              break
            case 406:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.auth.profile.notification.email_exists'))
              break
            case 404:
              await this.$router.push({
                name: 'Login',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.auth.profile.notification.not_found')
                  }
                }
              })
              break

            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.default.auth.profile.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  created () {
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: 'Mon profil',
        disabled: true,
        to: { name: 'Profile' }
      }
    ]
    this.form.rules = {
      type: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => _.includes(['private_school', 'public_school', 'company', 'local_government_authority'], value) || 'Le type selectionné n\'est pas autorisé.'
      ],
      lastname: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 75) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      firstname: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 75) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      email: [
        value => {
          if (_.size(value) <= 0) {
            return true
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (pattern.test(value)) || this.$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.email.rules.invalid')
        }
      ]
    }
  },
  watch: {
    'form.data.lastname': function (newVal, oldVal) {
      this.form.data.lastname = _.startCase(newVal)
    },
    'form.data.firstname': function (newVal, oldVal) {
      this.form.data.firstname = _.startCase(newVal)
    }
  },
  mounted () {
    this.fetchProfile()
  }
}
</script>
